/* General Resets */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff;
}

* {
  box-sizing: border-box;
}

/* Sidebar Styling */
.drawer-paper {
  width: 240px;
  background-color: #E3E3E3;
  color: #ecf0f1;
  position: fixed;
  height: 100vh;
}

.drawer-paper .MuiListItem-root {
  color: inherit;
}

.drawer-paper .MuiListItem-button:hover {
  background-color: #650707;
  color: #ffffff;
}

/* Main Content Styling */
.main-content {
  margin-left: 20px;
  padding: 20px;
  background-color: #ffffff;
  min-height: calc(100vh - 64px);
  position: relative;
  z-index: 1;
}

/* Header Styling */
.header-logo {
  height: 80px;
  margin-right: 16px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #650707 !important;
  color: #ecf0f1;
  padding: 0 16px;
  height: 64px;
  z-index: 2;
}

/* Yellow Box Styling */
.yellow-box {
  position: absolute;
  top: 64px;
  right: 0;
  width: 50%;
  height: 100px;
  background-color: #F6CD84;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  z-index: 10;
}

/* User Info and Image Styling */
.user-info {
  color: black;
  margin-left: 160px;
}

.user-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 20px;
}

/* Prevent content from overlapping with yellow box */
.main-content {
  margin-top: 150px;
  padding-top: 20px;
}

/* Footer Styling */
.footer {
  padding: 16px;
  background-color: #000000;
  color: #ffffff;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: calc(100% - 240px);
  left: 240px;
  z-index: 1;
}
