/* Sidebar Drawer */
.drawer-paper {
  width: 300px;
  box-sizing: border-box;
}

/* Accordion Styles */
.accordion-summary {
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
}

.accordion-details {
  background-color: #ffffff;
}

/* Menu Item Styles */
.menu-item {
  padding-left: 16px;
}

.menu-item .MuiListItemText-root {
  font-size: 14px;
}

/* Divider Styles */
.divider {
  margin: 16px 0;
}
