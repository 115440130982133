/* src/pages/UserProfile.css */

.profile-card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.profile-card img {
  border-bottom: 3px solid #650707;
}

.profile-card .MuiCardContent-root {
  padding: 20px;
}

.profile-card .MuiTypography-h5 {
  font-weight: bold;
  color: #650707;
}

.profile-card .MuiTypography-body1 {
  margin-top: 10px;
}

.profile-card .MuiCardActions-root {
  justify-content: center;
  padding: 16px;
}

button {
  margin-top: 20px;
}
