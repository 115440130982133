/* Scrolling Text Wrapper */
.scrolling-text-wrapper {
  position: absolute;
  top: 80px; /* Below the header */
  left: -200%; /* Start the scrolling text off-screen from the left */
  width: 300%; /* Ensure the text scrolls across a larger width */
  height: 40px; /* Adjust the height as necessary */
  overflow: hidden; /* Hide text when it's outside the container */
  z-index: 5; /* Ensure it's below the yellow box but above the background */
  display: flex;
  align-items: center;
  background-color: transparent; /* No background for scrolling text */
}

/* Scrolling Text */
.scrolling-text {
  display: inline-block;
  white-space: nowrap;
  animation: scroll-left 15s linear infinite;
}

/* Keyframe for scrolling */
@keyframes scroll-left {
  0% {
    transform: translateX(450%); /* Start the scrolling text fully off-screen to the right */
  }
  100% {
    transform: translateX(-100%); /* Scroll fully off-screen to the left */
  }
}
